
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="whatsapp://send?phone=+5567999581001&text=Olá!"><img src="/faixa.jpg" className="App-logo" alt="logo" /></a>
      </header>
      <div className="div-dev">

        <h1>
          Site em desenvolvimento
        </h1>
      
      </div>
    </div>
  );
}

export default App;
